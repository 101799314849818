import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28 mt-3" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "el-icon el-icon--upload" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "flex justify-end items-center 2xl:space-x-5 space-x-3 2xl:py-5 py-3" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 1,
  class: "flex"
}
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return ($setup.getIsLoadingDataCompany)
    ? (_openBlock(), _createBlock($setup["BaseSpinner"], {
        key: 0,
        fullScreen: ""
      }))
    : (_openBlock(), _createBlock($setup["Form"], {
        key: 1,
        "initial-values": 
      !$setup.isEmpty($setup.getDataCompanies.companyForm) ? $setup.getDataCompanies : $setup.initialData
    ,
        "validation-schema": $setup.schema,
        as: "el-form",
        onSubmit: $setup.onSubmit,
        "label-position": "top"
      }, {
        default: _withCtx(({ isSubmitting, setFieldValue }) => [
          _createVNode($setup["FieldArray"], { name: "companyForm" }, {
            default: _withCtx(({ fields, push, remove }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (field, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(isSubmitting && 'isSubmitting-rounded'),
                  key: field.key
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-2xl mt-5" }, "Company Information", -1)),
                    ($setup.isShowRemoveForm(field))
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "absolute top-0 right-0 text-red-500",
                          onClick: _withModifiers(($event: any) => ($setup.removeNewForm(idx, remove)), ["prevent"])
                        }, " X ", 8, _hoisted_2))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].CompanyName`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                            error: errorMessage,
                            label: `Company Name*`,
                            "model-value": value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error", "model-value"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].PhoneNumber`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "inputfield",
                            error: errorMessage,
                            label: `Company Contact No.*`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                "model-value": $setup.phoneCode[idx],
                                class: "w-1/5",
                                filterable: "",
                                onChange: (value) => $setup.handlePhoneCode(value, idx),
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PREFIX_PHONE_NUMBER, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.name,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["model-value", "onChange", "disabled"]),
                              _createVNode(_component_el_input, _mergeProps({
                                class: "w-9/12 ml-3",
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].Address`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass(['inputfield', _ctx.styles['w-full']]),
                            error: errorMessage,
                            label: `Address *`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].City`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                            error: errorMessage,
                            label: "City",
                            "model-value": value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error", "model-value"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].CompanyRegistrationNo`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                            error: errorMessage,
                            label: "Company Registration No.",
                            "model-value": value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error", "model-value"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].File`
                      }, {
                        default: _withCtx(({ value, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            error: errorMessage,
                            label: "Official documents / Certificates of Registration",
                            "model-value": value
                          }, {
                            default: _withCtx(() => [
                              (!$setup.isEdit || $setup.isEmpty($setup.getDataCompanies.companyForm))
                                ? (_openBlock(), _createBlock(_component_el_upload, {
                                    key: 0,
                                    class: _normalizeClass([$setup.size(value as any) > 2 ? 'upload-disable' : _ctx.styles['custom-upload-file']]),
                                    "before-upload": $setup.beforeUploadFile,
                                    drag: "",
                                    multiple: "",
                                    limit: 3,
                                    "auto-upload": false,
                                    "on-change": (file, showFileList) => $setup.onChangeFile(file, showFileList, value, setFieldValue, field),
                                    "on-remove": (file, showFileList) => $setup.handleRemoveUploadedFile(value, file, showFileList, setFieldValue, field),
                                    file: "",
                                    disabled: $setup.size(value as any) > 2,
                                    "on-preview": $setup.handlePreview
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("i", _hoisted_5, [
                                        _createVNode($setup["BaseSvgIcon"], {
                                          class: "inline-block group-hover:text-indino-primary w-6 h-6",
                                          name: "UploadFile"
                                        })
                                      ]),
                                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "el-upload__text" }, " Please upload your files here ", -1))
                                    ]),
                                    _: 2
                                  }, 1032, ["class", "on-change", "on-remove", "disabled"]))
                                : _createCommentVNode("", true),
                              (!$setup.isEdit || $setup.isEmpty($setup.getDataCompanies.companyForm))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Please only select a maximum of 3 files "))
                                : _createCommentVNode("", true),
                              ($setup.isEdit)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((value as any), (item, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "flex w-full justify-between",
                                        key: index
                                      }, [
                                        _createElementVNode("a", {
                                          class: "text-blue-500 underline truncate",
                                          href: item.Content,
                                          target: "_blank"
                                        }, _toDisplayString(item.Name), 9, _hoisted_8)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["error", "model-value"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createElementVNode("div", _hoisted_9, [
                        ($setup.isShowCreateForm)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "text-left",
                              onClick: _withModifiers(($event: any) => (
                  $setup.handleCreateNewForm((value) => push(value))
                ), ["prevent"])
                            }, [
                              _createVNode($setup["BaseSvgIcon"], {
                                class: "inline-block w-6 h-6",
                                name: "carbon_add-filled"
                              }),
                              _cache[5] || (_cache[5] = _createTextVNode(" Add more of companies in different country "))
                            ], 8, _hoisted_10))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].CountryId`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "inputfield",
                            error: errorMessage,
                            label: "Country of Registration*"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, _mergeProps({ ref_for: true }, field, {
                                "model-value": value,
                                class: "w-full",
                                placeholder: "Select Country",
                                filterable: "",
                                onInput: (value) => $setup.remoteMethod(value.target?.value),
                                onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.remoteMethod(''))),
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.Value,
                                      label: item.Name,
                                      value: item.Value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1040, ["model-value", "onInput", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].Province`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass(['inputfield', _ctx.styles['mt-46pc']]),
                            error: errorMessage,
                            label: "Province/State"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({
                                placeholder: "",
                                ref_for: true
                              }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      _createVNode($setup["Field"], {
                        name: `companyForm[${idx}].NumberOfWorkers`
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                            error: errorMessage,
                            label: "Number of workers in company"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, _mergeProps({ ref_for: true }, field, {
                                "model-value": value,
                                disabled: $setup.isEdit && !$setup.isEmpty($setup.getDataCompanies.companyForm)
                              }), null, 16, ["model-value", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1032, ["name"]),
                      (!$setup.isEdit)
                        ? (_openBlock(), _createBlock($setup["Field"], {
                            key: 0,
                            name: `companyForm[${idx}].File`
                          }, {
                            default: _withCtx(({ value, errorMessage }) => [
                              _createVNode(_component_el_form_item, {
                                class: "mt-20",
                                error: errorMessage,
                                "model-value": value
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((value as any), (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "flex w-full justify-between",
                                      key: index
                                    }, [
                                      _createElementVNode("a", {
                                        class: "text-blue-500 underline truncate",
                                        href: item.Content,
                                        target: "_blank"
                                      }, _toDisplayString(item.Name), 9, _hoisted_12)
                                    ]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["error", "model-value"])
                            ]),
                            _: 2
                          }, 1032, ["name"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "border-2 mt-14" }, null, -1))
                ], 2))
              }), 128))
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_13, [
            ($setup.isEmpty($setup.getDataCompanies.companyForm))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn--green-primary",
                  disabled: isSubmitting,
                  type: "submit"
                }, " Create ", 8, _hoisted_14))
              : (!$setup.isEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("button", {
                      class: "btn btn--black-primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.isEdit = true))
                    }, " Cancel "),
                    _createElementVNode("button", {
                      class: "btn btn--green-primary ml-5",
                      type: "submit",
                      disabled: $setup.isDisableSave
                    }, " Save ", 8, _hoisted_16)
                  ]))
                : (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "btn btn--green-primary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.isEdit = false))
                  }, " Edit "))
          ])
        ]),
        _: 1
      }, 8, ["initial-values", "validation-schema"]))
}