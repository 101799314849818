import { defineComponent as _defineComponent } from 'vue'
import { computeTabName } from "@/utils/hooks";

import { useRoute } from "vue-router";
import {
  HASH_COMPANY_ACCOUNT_MANAGEMENT,
  TAB_COMPANY_ACCOUNT_MANAGEMENT,
} from "@ems/constants";
import CompanyProfileForm from "@ems/containers/CompanyAccountManagement/CompanyProfile.Form.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const __returned__ = { route, get computeTabName() { return computeTabName }, get useRoute() { return useRoute }, get HASH_COMPANY_ACCOUNT_MANAGEMENT() { return HASH_COMPANY_ACCOUNT_MANAGEMENT }, get TAB_COMPANY_ACCOUNT_MANAGEMENT() { return TAB_COMPANY_ACCOUNT_MANAGEMENT }, CompanyProfileForm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})