import { defineComponent as _defineComponent } from 'vue'
import { Field, Form, FieldArray } from "vee-validate";
import { ref, computed, onMounted, watch } from "vue";
import { object, string, array } from "yup";
import MetadataModule from "@/store/modules/Metadata";
import { ElMessage } from "element-plus/lib/components/message";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { PREFIX_PHONE_NUMBER } from "@ems/constants/phone";
import CompanyModule from "./CompanyAccountManagement.module";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import { ICountries } from "@/models/Metadata";
import BaseSpinner from "@/components/BaseSpinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyProfile.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const initialData = {
  companyForm: [
    {
      CompanyName: "",
      PhoneNumber: "",
      Address: "",
      City: "",
      CompanyRegistrationNo: "",
      CountryId: "",
      Province: "",
      NumberOfWorkers: 0,
      isDelete: false,
    },
  ],
};

const NUMBER_FORM = {
  MAX_FORM: 5,
  MIN_FORM: 0,
  MAX_FILES: 3,
  VN_CODE: "+84",
  PLUS: "+",
};

const allCountries = computed(() => MetadataModule.allCountriesGetter);

const getDataCompanies = computed(
  () => CompanyModule.getDataListCompaniesGetter
);
const getIsLoadingDataCompany = computed(
  () => CompanyModule.isLoadingDataCompanyGetter
);

const handlePreview = (uploadFile: any) => {
  window.open(URL.createObjectURL(uploadFile.raw));
};

const schema = object().shape({
  companyForm: array()
    .of(
      object().shape({
        CompanyName: string().required().label("Company Name"),
        PhoneNumber: string().required().label("Phone Number"),
        Address: string().required().label("Address"),
        CountryId: string().required().label("Country Of Registration"),
      })
    )
    .strict(),
});

const beforeUploadFile = (file: any) => {
  const isLt20M = file.size / 1024 / 1024 < 20;

  if (!isLt20M) {
    ElMessage.error("File size can not exceed 20MB!");
  }
  return isLt20M;
};

const onChangeFile = (
  file: any,
  showFileList: any,
  value: any,
  setFieldValue: any,
  fields: any
) => {
  if (size(showFileList) + size(value) > NUMBER_FORM.MAX_FILES) {
    ElMessage.error("Do not upload more than 3 files!");
    isDisableSave.value = true;
  } else {
    setFieldValue(`companyForm[${fields.key}].FileUpload`, showFileList);
  }
};

const handleRemoveUploadedFile = (
  value: any,
  file: any,
  showFileList: any,
  setFieldValue: any,
  field: any
) => {
  if (
    showFileList.length + value?.length <= NUMBER_FORM.MAX_FILES &&
    isDisableSave.value
  ) {
    ElMessage.success("You can update uploaded files!");
    isDisableSave.value = false;
  }
  setFieldValue(`companyForm[${field.key}].FileUpload`, showFileList);
};

const errorMessageRef = ref("");
const phoneCode = ref(["+84"] as any);
const amountForm = ref(0);
const isEdit = ref(true);
const isDisableSave = ref(false);

const getPhoneNumber = () => {
  if (!isEmpty(getDataCompanies)) {
    getDataCompanies.value.companyForm.forEach((items: any, idx: number) => {
      phoneCode.value[idx] = items.phoneCode;
    });
  }
};

const getAmountForm = () => {
  amountForm.value = getDataCompanies.value.companyForm.length;
};

const isShowCreateForm = computed(
  () =>
    (isEmpty(getDataCompanies.value.companyForm) &&
      amountForm.value < NUMBER_FORM.MAX_FORM) ||
    (!isEdit.value && amountForm.value < NUMBER_FORM.MAX_FORM)
);

const isShowRemoveForm = (field: any) => {
  return field.value.isDelete;
};

const onSubmit = async (values: any) => {
  const listCreateCompany = [] as any;
  const listUpdateCompany = [] as any;

  values.companyForm.forEach((element: any, i: number) => {
    element.PhoneNumber = phoneCode.value[i] + "-" + element.PhoneNumber;
    if (!element.Id) {
      listCreateCompany.push(CompanyModule.createDataCompany(element));
    } else {
      listUpdateCompany.push(CompanyModule.updateDataCompany(element));
    }
  });

  if (!isEmpty(listCreateCompany)) {
    await Promise.all(listCreateCompany);
  }

  if (!isEmpty(listUpdateCompany)) {
    await Promise.all(listUpdateCompany);
  }

  CompanyModule.getDataCompany();
  isEdit.value = true;
};

const handleCreateNewForm = (push: any) => {
  amountForm.value += 1;
  phoneCode.value.push(NUMBER_FORM.VN_CODE);
  initialData.companyForm[0].isDelete = true;
  push(initialData.companyForm[0]);
};
const removeNewForm = (idx: number, remove: any) => {
  amountForm.value -= 1;
  remove(idx);
};

onMounted(async () => {
  await CompanyModule.getDataCompany();

  filterOptionCountry();
  getPhoneNumber();
  MetadataModule.getAllCountryAction();
  getAmountForm();
});

const options = ref<ICountries[]>([]);
const remoteMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      options.value = allCountries.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else if (query === "") {
    options.value = allCountries.value;
  } else {
    options.value = [];
  }
};
watch(allCountries, () => {
  filterOptionCountry();
});

const filterOptionCountry = () => {
  options.value = allCountries.value.map((item) => {
    return { Value: `${item.Value}`, Name: `${item.Name}` };
  });
};

const handlePhoneCode = (query: string, idx: number) => {
  phoneCode.value[idx] = query;
};

const __returned__ = { initialData, NUMBER_FORM, allCountries, getDataCompanies, getIsLoadingDataCompany, handlePreview, schema, beforeUploadFile, onChangeFile, handleRemoveUploadedFile, errorMessageRef, phoneCode, amountForm, isEdit, isDisableSave, getPhoneNumber, getAmountForm, isShowCreateForm, isShowRemoveForm, onSubmit, handleCreateNewForm, removeNewForm, options, remoteMethod, filterOptionCountry, handlePhoneCode, get Field() { return Field }, get Form() { return Form }, get FieldArray() { return FieldArray }, ref, computed, onMounted, watch, get object() { return object }, get string() { return string }, get array() { return array }, get MetadataModule() { return MetadataModule }, get ElMessage() { return ElMessage }, BaseSvgIcon, get PREFIX_PHONE_NUMBER() { return PREFIX_PHONE_NUMBER }, get CompanyModule() { return CompanyModule }, get isEmpty() { return isEmpty }, get size() { return size }, get ICountries() { return ICountries }, BaseSpinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})