import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "container my-3 py-3 px-10" }
const _hoisted_4 = { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }
const _hoisted_5 = {
  class: "text-2xl 2xl:text-4xl font-bold",
  "data-aos": "fade-right"
}
const _hoisted_6 = { class: "grid grid-cols-10 gap-5" }
const _hoisted_7 = { class: "col-span-2" }
const _hoisted_8 = {
  type: "button",
  class: /*@__PURE__*/_normalizeClass(['green-tab-box active'])
}
const _hoisted_9 = { class: "col-span-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: `/company-account-management#company_profile` }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Company Account Management ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, _toDisplayString($setup.TAB_COMPANY_ACCOUNT_MANAGEMENT.COMPANY_PROFILE), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH_COMPANY_ACCOUNT_MANAGEMENT), (value) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "inline-block mb-3",
              key: value
            }, [
              _createElementVNode("button", _hoisted_8, _toDisplayString($setup.computeTabName($setup.TAB_COMPANY_ACCOUNT_MANAGEMENT, value)), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode($setup["CompanyProfileForm"], { class: "box" })
        ])
      ])
    ])
  ]))
}